import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "../node_modules/vue-agile/dist/VueAgile.css";

import { setBaseUrl } from "./request";

if (process.env.VUE_APP_API_URL) {
  setBaseUrl(process.env.VUE_APP_API_URL);
}

createApp(App).use(store).use(router).mount("#app");
