import { createStore } from "vuex";
import { make } from "@/request";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Toast from "@/classes/Toast";
import ErrorParse from "@/classes/ErrorParse";
import { markRaw } from "vue";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      state: {
        address: null,
        user: null,
        connection: null,
      },
      mutations: {
        SET_ADDRESS(state, payload) {
          state.address = payload;
        },
        SET_USER(state, payload) {
          state.user = payload;
        },
        SET_CONNECTION(state, payload) {
          state.connection = {
            signer: markRaw(payload?.signer),
            provider: markRaw(payload?.provider),
            address: payload?.address,
            network: markRaw(payload?.network),
          };
        },
        CLEAR_CONNECTION(state) {
          state.connection = null;
        },
      },
      actions: {
        async getUser({ commit }) {
          let request;
          try {
            request = await make({ name: "user" });
          } catch (e) {
            console.log("not logged in", { e });
            commit("SET_ADDRESS", null);
            commit("SET_USER", null);

            return;
          }

          commit("SET_ADDRESS", request?.wallet_address);
          commit("SET_USER", request);

          return;
        },
        async loginToken({ commit, dispatch }, token) {
          let request;
          try {
            request = await make({
              name: "loginToken",
              data: {
                token,
              },
            });
          } catch (e) {
            console.log("not logged in from token", { e });
            commit("SET_ADDRESS", null);
            commit("SET_USER", null);

            dispatch("toasts/add", { message: e, variant: "error" }, { root: true });

            throw e;
          }

          let userRequest;
          try {
            userRequest = await make({ name: "user" });
          } catch (e) {
            console.log("not logged in", { e });
            commit("SET_ADDRESS", null);
            commit("SET_USER", null);

            return;
          }

          dispatch("toasts/add", { message: "Logged In!", variant: "success" }, { root: true });

          commit("SET_ADDRESS", userRequest?.wallet_address);
          commit("SET_USER", userRequest);

          return;
        },
        async clearListeners({ state }) {
          if (state?.connection?.provider) {
            state.connection.provider.provider.removeAllListeners();
          }
          return;
        },
        async startListeners({ commit, dispatch, state }) {
          await dispatch("clearListeners");

          if (state?.connection?.provider) {
            state?.connection?.provider.provider.on("accountsChanged", (accounts) => {
              console.log("account changed: ", accounts);
              dispatch("connect", true);
            });

            // Subscribe to chainId change
            state?.connection?.provider.provider.on("chainChanged", (chainId) => {
              console.log("Chain changed: ", chainId);
              dispatch("connect", true);
            });

            // Subscribe to session disconnection
            state?.connection?.provider.provider.on("disconnect", (code, reason) => {
              console.log("Walled disconnected: ", code, reason);
              commit("CLEAR_CONNECTION");
            });
          }

          return;
        },
        async connect({ commit, dispatch, state }, listenersOn) {
          await dispatch("clearListeners");

          const providerOptions = {
            walletconnect: {
              package: WalletConnectProvider, // required
              options: {
                infuraId: "18436e45322646beae413025603f5fe0", // required
              },
            },
          };

          const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: false, // optional
            providerOptions, // required
          });

          let signer, provider, network;
          try {
            const cleared = await web3Modal.clearCachedProvider();
            const instance = await web3Modal.connect();
            provider = new ethers.providers.Web3Provider(instance);
            signer = await provider.getSigner();
            network = await provider.getNetwork();
          } catch (e) {
            console.log("web3 rejected", { e });
            dispatch("toasts/add", { message: "Web3 connection request rejected by user", variant: "error" }, { root: true });
            commit("CLEAR_CONNECTION");
            return false;
          }

          const address = await signer.getAddress();

          commit("SET_CONNECTION", { signer, provider, address, network });

          if (listenersOn) {
            dispatch("startListeners");
          }

          return { signer, provider, address, network };
        },
        async disconnect({ commit, dispatch }) {
          await dispatch("clearListeners");
          commit("CLEAR_CONNECTION");
          return;
        },
        async login({ commit, dispatch, state }) {
          if (state.address) {
            let loginCheck;
            try {
              loginCheck = await make({ name: "user" });
            } catch (e) {
              console.log("not logged in", { e });
              return;
            }

            commit("SET_ADDRESS", loginCheck?.wallet_address);
            commit("SET_USER", loginCheck);
            return;
          }

          const connectRequest = await dispatch("connect");

          if (!connectRequest) {
            return;
          }

          let signature;
          try {
            signature = await connectRequest.signer.signMessage("Enter Castle Club");
          } catch (e) {
            dispatch("toasts/add", { message: "Web3 signature request rejected by user", variant: "error" }, { root: true });
            return;
          }

          try {
            const request = await make({ name: "login", data: { address: connectRequest.address, signature } });
          } catch (e) {
            dispatch("toasts/add", { message: ["Login error", e], variant: "error" }, { root: true });
            return;
          }

          let loggedInCheck;
          try {
            loggedInCheck = await make({ name: "user" });
          } catch (e) {
            console.log("not logged in", { e });
            return;
          }

          commit("SET_ADDRESS", connectRequest.address);
          commit("SET_USER", loggedInCheck);

          return;
        },
        async logout({ commit }) {
          try {
            const request = await make({ name: "logout" });
          } catch (e) {
            // return e;
          }

          commit("SET_ADDRESS", null);
          commit("SET_USER", null);
        },
        async authToken() {
          return await make({ name: "userAuthToken" });
        },
      },
      getters: {
        isLoggedIn(state) {
          return !!state?.address;
        },
      },
    },
    toasts: {
      namespaced: true,
      state: {
        stack: [],
        index: 1,
      },
      mutations: {
        ADD_TOAST(state, payload) {
          state.index += 1;
          // Vue.set(state, "stack", [...state.stack, payload]);
          state.stack = [...state.stack, payload];
        },
        REMOVE_TOAST(state, payload) {
          let newStack = state.stack.slice();
          const foundIndex = payload.id ? newStack.findIndex((val) => val.id === payload.id) : newStack.findIndex((val) => val.message === payload.message);
          newStack.splice(foundIndex, 1);
          // Vue.set(state, "stack", [...newStack]);
          state.stack = [...newStack];
        },
        CLEAR_ALL(state) {
          state.stack = [];
          // Vue.set(state, "stack", []);
        },
      },
      actions: {
        add({ commit, state }, payload) {
          let added = payload;
          if (!(payload instanceof Toast)) {
            added = new Toast({ ...payload, id: state.index });
          }

          commit("ADD_TOAST", added);
        },
        remove({ commit }, payload) {
          let removed = payload;
          if (!(payload instanceof Toast)) {
            removed = new Toast(payload);
          }

          commit("REMOVE_TOAST", removed);
        },
      },
      getters: {
        getStack: (state) => state.stack,
      },
    },
    suggestions: {
      namespaced: true,
      state: {
        list: [],
        // item: null,
        createEdit: null,
      },
      mutations: {
        SET_LIST(state, payload) {
          state.list = payload ? payload : [];
        },
        SET_SHOW_CREATE_EDIT(state, payload) {
          state.createEdit = payload;
        },
      },
      actions: {
        setShowCreateEdit({ commit }, payload) {
          commit("SET_SHOW_CREATE_EDIT", payload);
        },
        async getList({ commit }) {
          let request;
          try {
            request = await make({ name: "suggestionsGet" });
          } catch (e) {
            console.log("can't get suggestions", { e });
            commit("SET_LIST", null);
            return;
          }

          commit("SET_LIST", request);

          return request;
        },
        async get({ dispatch }, id) {
          let request;

          try {
            request = await make({ name: "suggestionGet", params: { id } });
          } catch (e) {
            dispatch("toasts/add", { message: e, variant: "error" }, { root: true });
            return;
          }

          return request;
        },
        async make({ dispatch }, data) {
          let request;

          try {
            request = await make({ name: "suggestionMake", data });
            dispatch("toasts/add", { message: "Suggestion created!", variant: "success" }, { root: true });
          } catch (e) {
            dispatch("toasts/add", { message: new ErrorParse(e), variant: "error" }, { root: true });
            return;
          }

          return request;
        },
      },
    },
  },
});
