<script>
import { onBeforeUnmount, onMounted, ref } from "vue";
// import Btn from "@/components/display/Btn";
// import Icon from "@/components/display/icon/Icon";
import store from "@/store";
import Toast from "@/classes/Toast";

export default {
  name: "ToastComp",
  props: {
    toast: Toast,
  },
  components: {
    // Icon,
    // Btn,
  },
  setup(props) {
    const timer = ref(props.toast?.timeout);
    const interval = ref(null);
    const countdownAmount = 25;

    const close = () => {
      if (props.toast.dismissible) {
        store.dispatch("toasts/remove", props.toast);
      }
    };

    onMounted(() => {
      if (timer.value && timer.value > 0) {
        interval.value = setInterval(() => {
          if (timer.value <= 0) {
            close();
          }
          timer.value -= countdownAmount;
        }, countdownAmount);
      }
    });

    onBeforeUnmount(() => {
      if (interval.value) {
        clearInterval(interval.value);
      }
    });

    return {
      close,
      timer,
    };
  },
};
</script>

<template>
  <div class="toast relative bg-white mb-4 flex flex-col rounded-lg overflow-hidden box-shadow-std border border-gray-200" style="max-width: 300px" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-timer h-1" :class="'toast-timer-variant-' + toast.variant" :style="{ width: toast.timeout ? `${(timer / toast.timeout) * 100}%` : '100%' }"></div>
    <div class="toast-header flex flex-col px-4 py-2" :class="'toast-header-variant-' + toast.variant">
      <div class="font-semibold font-sans" v-if="toast.title">{{ toast.title }}</div>
      <div class="font-sans mr-auto toast-message" v-for="(message, m) in toast.messages" :key="m">
        {{ message }}
      </div>
      <div class="w-full self-stretch">
        <div class="pt-2 underline cursor-pointer text-right text-sm" @click="close">close</div>
      </div>
      <!-- <button
        class="close absolute -right-2 bg-white rounded-full shadow text-black -top-3"
        data-dismiss="toast"
        style="width: 30px; height: 30px"
        aria-label="Close"
        @click="close"
        v-if="toast.dismissible"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 72 72"
          style="enable-background: new 0 0 72 72"
          xml:space="preserve"
        >
          <g>
            <path
              fill="currentColor"
              d="M10.6,10.6C17.6,3.5,26.1,0,36,0s18.4,3.5,25.4,10.6S72,26.1,72,36s-3.5,18.4-10.6,25.4S45.9,72,36,72s-18.4-3.5-25.4-10.6
		S0,45.9,0,36S3.5,17.6,10.6,10.6z M51.1,47.1L40,36l11.1-11.1l-4-4L36,32L24.9,20.9l-4,4L32,36L20.9,47.1l4,4L36,40l11.1,11.1
		L51.1,47.1z"
            />
          </g>
        </svg>
      </button> -->
    </div>
  </div>
</template>

<style lang="pcss">
.toast-timer-variant-default {
  @apply bg-blue-400;
}

.toast-timer-variant-success {
  @apply bg-green-400;
}

.toast-timer-variant-error {
  @apply bg-red-500;
}
.toast-header-variant-default {
  @apply bg-white;
}
.toast-header-variant-success {
  @apply bg-green-100;
}

.toast-header-variant-error {
  @apply bg-red-100;
}
</style>
