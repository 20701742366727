import RequestDefinition from "../classes/RequestDefinition";
import RequestGroupDefinition from "../classes/RequestGroupDefinition";

export const getCsrfCookie = new RequestDefinition({
  path: "sanctum/csrf-cookie",
  method: "get",
});

export const login = new RequestGroupDefinition({
  definitions: [
    new RequestDefinition({
      path: "sanctum/csrf-cookie",
      method: "get",
    }),
    new RequestDefinition({
      path: "login",
      method: "post",
    }),
  ],
});

export const loginToken = new RequestGroupDefinition({
  definitions: [
    new RequestDefinition({
      path: "sanctum/csrf-cookie",
      method: "get",
    }),
    new RequestDefinition({
      path: "login-token",
      method: "post",
    }),
  ],
});

export const logout = new RequestGroupDefinition({
  definitions: [
    new RequestDefinition({
      path: "sanctum/csrf-cookie",
      method: "get",
    }),
    new RequestDefinition({
      path: "logout",
      method: "post",
    }),
  ],
});

export const user = new RequestDefinition({
  path: "user",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const userAuthToken = new RequestDefinition({
  path: "auth-token",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const chapterOneGetDay = new RequestDefinition({
  path: "chapter-one/day",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const chapterOneCreateDay = new RequestDefinition({
  path: "chapter-one/day",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const chapterOneLockDay = new RequestDefinition({
  path: "chapter-one/day",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const chapterOneResetDay = new RequestDefinition({
  path: "chapter-one/day/reset",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const chapterOneGetTokens = new RequestDefinition({
  path: "chapter-one/tokens",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tokens ?? null;
  },
});

export const chapterOneStartGame = new RequestDefinition({
  path: "chapter-one/game",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const chapterOneGameDraw = new RequestDefinition({
  path: "chapter-one/game",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.day ?? null;
  },
});

export const chapterOneGamePowerup = new RequestDefinition({
  path: "chapter-one/game/powerup",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const chapterOneLeaderboard = new RequestDefinition({
  path: "chapter-one/leaderboard",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const chapterOneTempGameGet = new RequestDefinition({
  path: "chapter-one/temp-game",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const chapterOneTempGameStart = new RequestDefinition({
  path: "chapter-one/temp-game",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const chapterOneTempGameDraw = new RequestDefinition({
  path: "chapter-one/temp-game",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const suggestionsGet = new RequestDefinition({
  path: "suggestions",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.suggestions ?? null;
  },
});

export const suggestionGet = new RequestDefinition({
  path: "suggestions/{id}",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionMake = new RequestDefinition({
  path: "suggestions",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionUpdate = new RequestDefinition({
  path: "suggestions/{id}",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionDelete = new RequestDefinition({
  path: "suggestions/{id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const suggestionGetComments = new RequestDefinition({
  path: "suggestions/{id}/comments",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.suggestion ?? null;
  },
});

export const suggestionCommentMake = new RequestDefinition({
  path: "comments",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.comment ?? null;
  },
});

export const suggestionCommentUpdate = new RequestDefinition({
  path: "comments/{id}",
  method: "put",
  transformResponse: (response) => {
    return response.data?.content?.comment ?? null;
  },
});

export const suggestionCommentDelete = new RequestDefinition({
  path: "comments/{id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const suggestionVoteMake = new RequestDefinition({
  path: "votes",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content?.vote ?? null;
  },
});

export const suggestionVoteDelete = new RequestDefinition({
  path: "votes/{id}",
  method: "delete",
  transformResponse: (response) => {
    return response.data?.content?.successes ?? null;
  },
});

export const galleryGet = new RequestDefinition({
  path: "gallery",
  method: "get",
  transformResponse: (response) => {
    return {
      tokens: response.data?.content?.tokens ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters,
    };
  },
});

export const galleryGetFilters = new RequestDefinition({
  path: "gallery/filters",
  method: "get",
  transformResponse: (response) => {
    return response?.data?.content;
    // return {
    //   tokens: response.data?.content?.tokens ?? [],
    //   current_page: response.data?.content?.current_page,
    //   total_pages: response.data?.content?.total_pages,
    //   sort_by: response.data?.content?.sort_by,
    //   sort_direction: response.data?.content?.sort_direction,
    //   filters: response.data?.content?.filters,
    // };
  },
});
